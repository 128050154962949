import { AccountId, OrgId } from "@ollie-sports/models";
import { getServerHelpers } from "../../helpers";

export async function tryouts__server__getTryoutEventsForOrgs(p: { orgIds: OrgId[] }) {
  // SERVER_ONLY_TOGGLE
  const { appOllieFirestoreV2: h } = getServerHelpers();

  try {
    const tryoutEventResults = (
      await h.OpenOrgEvent.query({
        where: [
          {
            orgId: ["in", p.orgIds]
          }
        ]
      })
    ).docs;
    return tryoutEventResults;
  } catch (e) {
    return [];
  }

  // SERVER_ONLY_TOGGLE
}

tryouts__server__getTryoutEventsForOrgs.auth = () => {};
// i18n certified - complete
